<template>
  <div class="row cloud-header ">
    <div class="col-12">
      <div class="card welcome-card ">
        <v-gravatar v-if="email" :email="email" alt="user-image" class="rounded-circle" default-img="mp" />

        <h1>{{ title }}</h1>
        <p class="color-muted">
          {{ subtitle }}
        </p>

        <div v-if="stats" class="text-primary">
          <div>💾 Συνολικές λήψεις: <span class="font-weight-bold">{{stats.downloads}}</span>
          </div>
          <div>❤️ Συνολικά Likes:   <span class="font-weight-bold">{{stats.likes}}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rounded-circle{
  max-width:60px;
}
.welcome-card{
  padding:5em 3em;
  background-color:#e3ebfe!important;
  background-image: url('~~~@assets/images/cloud-bg-item.svg');
  background-size:800px;
  background-repeat:no-repeat;
  background-position: center right;



  h1{
    color:$color_primary;
    font-weight:700;
  }
  p{
    max-width:50%;
    @media(max-width:767px){
      max-width:100%;
    }
    color:$color_primary;
    opacity:0.7;
    letter-spacing: 1.1px;
  }
}
</style>
<script>
export default{

  props:{
    title: String,
    subtitle: String,
    email: String,
    stats: Object
  }
}
</script>
