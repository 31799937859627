<template>
  <div id="cloud-recipes-layout">

    <cloud-header title="Συνταγές" subtitle="Εξερευνήστε & μοιραστείτε συνταγές. Χρησιμοποιήστε τις ετικέτες και την αναζήτηση
    για ακόμα πιο εξατομικευμένα αποτελέσματα"></cloud-header>

    <div class="filters">

      <div class="row">
        <div class="col-12 col-md-4">
          <b-form-input v-model="query.search" placeholder="Αναζητήστε με βάση το όνομα..." debounce="400"/>
        </div>
        <div class="col-12 col-md-4">
          <b-form-select v-model="query.order" :options="orderings" class="mb-3">
          </b-form-select>
        </div>
        <div class="col-12 col-md-4">
          <b-button variant="light" @click="showTagList = !showTagList">
            Αναζήτηση ανά ετικέτα <i v-if="!showTagList" class="fe-arrow-down"></i> <i v-if="showTagList" class="fe-arrow-up"></i></b-button>
        </div>
      </div>
    </div>

    <div v-if="showTagList || query.tagIds.length > 0" class="choose-from-tags row">
      <div class="col-md-12">

        <b-button variant="outline-primary" class="tag-btn" :class=" (query.tagIds.length === 0) ?
            'active-tag-btn' : '' " @click="query.tagIds = []">Προβολή όλων</b-button>
        <span v-for="tag in tags" :key="tag.id">
                            <b-button variant="outline-primary" class="tag-btn" :class="query.tagIds.includes(tag.id)? 'active-tag-btn' : '' "
                                      @click="query.tagIds = [tag.id]"><i class="fe-tag"></i> {{tag.text}}</b-button>
                        </span>
      </div>
    </div>

    <div class="row">
      <div v-for="dp in listings" :key="dp.id" class="col-12 col-md-6">
        <cloud-listing :item="dp"/>
      </div>
    </div>


    <ul v-if="initialLoadFinished" class="pagination pagination-rounded mb-0 float-right">
      <!-- pagination -->
      <b-pagination v-model="page" :total-rows="query.totalRows" :per-page="query.perPage" @input="fetchRecipes"></b-pagination>
    </ul>
  </div>
</template>


<script>
import CloudHeader from "@components/cloud/CloudHeader";
import CloudListing from "@components/cloud/CloudListing";
import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      tags: state => state.cloud._cloudTags,
      recipeQuery: state => state.cloud._recipeQuery
    })
  },
  components: {CloudHeader, CloudListing},
  data(){
    return{
      query:{
        tagIds: [],
        search: null,
        perPage: 10,
        totalRows: 0,
        order: 'latest'
      },
      showTagList: false,
      page: 1,
      listings: [],
      orderings: [
        {value: 'latest', text: 'Πιο πρόσφατα'},
        {value:'oldest', text: 'Πιο παλιά'}, {value:'downloads', text: 'Περισσότερα downloads'}, {value:'likes',text: 'Περισσότερα likes'}],
      initialLoadFinished: false
    }
  },

  watch:{
    query:{
      handler(val){
        if(!this.initialLoadFinished) return;
        this.page = 1;
        this.$store.dispatch('cloud/setRecipeQuery', {...this.query, page: this.page});
        this.fetchRecipes()
      },
      deep: true
    },
    page(val){
      this.$store.dispatch('cloud/setRecipeQuery', {...this.query, page: val});
    }
  },
  mounted(){
    if(this.recipeQuery){
      this.query = {...this.recipeQuery}
      this.page = this.recipeQuery.page
    }
    this.fetchRecipes();

  },
  methods:{
    fetchRecipes(){

      const query = {
        limit: this.query.perPage,
        offset: (this.page - 1) * this.query.perPage,
        type: 'recipes'
      };



      if(this.query.tagIds) query.tags = this.query.tagIds;
      if(this.query.search) query.search = this.query.search;
      if(this.query.order) query.order = this.query.order;

      this.$axios.get('/cloud/listings', {params:query}).then(res => {
        this.query.totalRows = res.data.count;
        this.listings = res.data.rows;
        this.initialLoadFinished = true;
      }).catch(e=>{
        console.log(e);
      });

    }
  }
}
</script>
