<template>

  <div class="cloud-listing-item-wrapper ">
    <div class="row align-items-center">
      <div class="col-9">
        <div class="float-left mr-2">
          <div v-if="item.recipe" :src="item.recipe.image" :style="'background-image: url('+item.recipe.image + ');'" class="rounded-image cloud-recipe-image mb-2"/>
        </div>


        <div class="ml-2">
          <div class="tag-container">
            <div v-for="tag in item.tags" :key="tag" class=" text-primary tag-item mr-2">#{{getTagText(tag)}}</div>
          </div>


          <h4 class="text-primary" @click="showListing(item)">{{item.title}}</h4>




          <span v-if="!item.foodId" class="mr-2">💾 {{item.downloads}}</span>
          <span v-if="!item.foodId" class="mr-2">❤️ {{item.likes}}</span>
          <div v-if="!$route.params.userId" class="published-by mt-2">
            <router-link :to="{name: 'cloud-user', params:{userId: item.user.id}}" class="text-primary font-weight-bold">
              <v-gravatar v-if="item.user" :email="item.user.email" alt="user-image" class="rounded-circle" default-img="mp" style="max-width:25px" />
              {{item.user.firstName}} {{item.user.lastName}}
            </router-link>
          </div>
      </div>

    </div>
      <div class="col-3 text-right">
        <b-button variant="warning" v-if="showEditButton && !item.foodId" class="mr-2" @click="updateListing"><i class="fa fa-edit"></i></b-button>
        <b-button variant="danger" v-if="showDeleteButton && !item.foodId" @click="deleteListing"><i class="icon-trash"></i></b-button>

      </div>

      </div>

    </div>
</template>

<style lang="scss" scoped>
.cloud-listing-item-wrapper{
  .cloud-recipe-image{
    height:80px;
    width:140px;
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
  }
  h4{
    cursor:pointer;
  }
  .tag-container{
    color:#000;
  }
  background-color:#fff;
  box-shadow: 0 8px 40px #E8EDF1B3;
  padding:.5em 1em;
  margin-top:1em;
  border-radius:12px;
}
</style>
<script>

import {mapState} from "vuex";

export default{

  props:{
    item:Object,
    showDeleteButton:{
      type: Boolean,
      default: false
    },
    showEditButton:{
      type: Boolean,
      default: false
    }
  },

  computed:{
    ...mapState({
      tags: state => state.cloud._cloudTags
    })
  },
  methods:{
    showListing(item){
      this.$router.push({name: 'cloud-listing', params: {listingId: item.id}})
      this.$store.dispatch('cloud/setSelectedListing', item.id);
      // this.$store.dispatch('cloud/setShowPreviewModal', true);

    },
    getTagText(tagId){
      return this.tags.filter(x => parseInt(x.id) === parseInt(tagId))[0].text
    },

    updateListing(){
      this.$emit('updateListing', this.item);
    },

    deleteListing(){
      let message = {
        title: 'Θέλετε σίγουρα να διαγράψετε αυτή την εγγραφή; ',
        body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, θα χαθούν όλες οι λήψεις, τα Likes που έχετε λάβει και δεν θα είναι πια διαθέσιμο στο athlisis cloud.'
      };
      this.$dialog.confirm(message, {
        loader: true,
        okText: 'Διαγραφή από το cloud',
        cancelText: 'Ακύρωση',
        backdropClose: true
      }).then((dialog) => {
        this.$axios.delete(`/cloud/listings/${this.item.id}`).then(async result => {
          dialog.close();
          this.$emit('deletedListing')
          this.$notify({group: 'athlisis-notifications', type: 'success', title: 'Επιτυχία!', text: 'Το πρότυπο διαγράφηκε επιτυχώς!'});
        }).catch(e => {
          this.$notify({group: 'athlisis-notifications', type: 'error', title: 'Κάτι πήγε λάθος!', text: 'Η διαγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
        });
      }).catch(()=>{});
    }
  },

}
</script>
